import {
 API_KEY, API_SECRET, IProviderInputInterface, SAME_VALUE,
} from './payment-provider-types';

export const voltConfigs: IProviderInputInterface[] = [
    {
      name: 'username',
      label: 'Username',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      keyType: API_SECRET,
      isRequired: true,
    },
    {
      name: 'publicKeyId',
      label: 'Public Key ID',
      type: 'text',
      keyType: API_KEY,
      isRequired: false,
    },
    {
      name: 'privateKey',
      label: 'Private Key',
      type: 'password',
      keyType: API_SECRET,
      isRequired: false,
    },
  ];

  export const visaCardConfigs = [
    {
      name: 'merchantId',
      label: 'Merchant ID',
      type: 'text',
      keyType: API_KEY,
    },
    {
      name: 'apiKey',
      label: 'Key',
      type: 'text',
      keyType: API_KEY,
    },
    {
      name: 'sharedSecret',
      label: 'Shared Secret',
      type: 'password',
      keyType: API_SECRET,
    },
  ];

export const tabbyConfigs: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Please enter api key',
  },
  {
    name: 'merchantCode',
    label: 'Merchant Code',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Please enter Merchant Code',
  },
  {
    name: 'apiSecret',
    label: 'Api Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Please enter Api secret',
  },
];

export const grabConfigs: IProviderInputInterface[] = [
  {
    name: 'partnerId',
    label: 'Partner ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter partner ID',
  },
  {
    name: 'clientId',
    label: 'Client ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter client ID',
  },
  {
    name: 'merchantId',
    label: 'Merchant ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter merchant ID',
  },
  {
    name: 'partnerSecret',
    label: 'Partner Secret',
    type: 'password',
    keyType: API_SECRET,
    infoGuide: 'Please enter partner secret',
  },
  {
    name: 'clientSecret',
    label: 'Client Secret',
    type: 'password',
    keyType: API_SECRET,
    infoGuide: 'Please enter client secret',
  },
];

  export const zipConfig: IProviderInputInterface[] = [
    {
      name: 'Api-Key',
      label: 'API Key',
      type: 'password',
      keyType: SAME_VALUE,
      infoGuide: 'Please Enter credentials provided by provider',
    },
  ];

  export const fiservCardsAuConfig: IProviderInputInterface[] = [
    {
      name: 'apiKey',
      label: 'API Key',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
      infoGuide: 'Please enter API Key',
    },
    {
      name: 'storeId',
      label: 'Store ID',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
      infoGuide: 'Please enter Store ID',
    },
    {
      name: 'sharedSecret',
      label: 'Shared Secret',
      type: 'password',
      keyType: API_KEY,
      isRequired: true,
      infoGuide: 'Please enter Shared Secret',
    },
    {
      name: 'apiSecret',
      label: 'API Secret',
      type: 'password',
      keyType: API_SECRET,
      isRequired: true,
      infoGuide: 'Please enter API Secret',
    },
  ];

  export const tamaraConfig: IProviderInputInterface[] = [
    {
      name: 'apiKey',
      label: 'Api Key',
      type: 'password',
      keyType: SAME_VALUE,
      isRequired: true,
      infoGuide: 'Please enter Api Key',
    },
  ];

  export const crediteaConfig: IProviderInputInterface[] = [
    {
      name: 'apiKey',
      label: 'Api Key',
      type: 'password',
      keyType: SAME_VALUE,
      isRequired: true,
      infoGuide: 'Please enter Api Key',
    },
  ];

  export const hummConfigs: IProviderInputInterface[] = [
    {
      name: 'apiKey',
      label: 'Api Key',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
      infoGuide: 'Please enter Api Key',
    },
    {
      name: 'storeId',
      label: 'Store ID',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
      infoGuide: 'Please enter valid Store ID',
    },
    {
      name: 'apiSecret',
      label: 'Api Secret',
      type: 'password',
      keyType: API_SECRET,
      isRequired: true,
      infoGuide: 'Please enter Api Secret',
    },
    {
      name: 'refreshToken',
      label: 'Refresh Token',
      type: 'text',
      keyType: API_SECRET,
      isRequired: true,
      infoGuide: 'Please enter Refresh Token',
    },
  ];

export const swishConfigs = [
  {
    name: 'cert',
    label: 'cert',
    type: 'text',
    keyType: 'apiKey',
  },
  {
    name: 'key',
    label: 'key',
    type: 'text',
    keyType: 'apiSecret',
  },
  {
    name: 'ca',
    label: 'ca',
    type: 'text',
    keyType: 'apiSecret',
  },
];
  export const squareConfig: IProviderInputInterface[] = [
    {
      name: 'applicationID',
      label: 'Application ID',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
      infoGuide: 'Login to Square developer portal, Navigate to Applications > Select / Create Application > Credentials to View your API key.',
    },
    {
      name: 'locationId',
      label: 'Location Id',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
      infoGuide: 'Login to Square developer portal, Navigate to Applications > Select / Create Application > Locations to View/Create your Location ID',
    },
    {
      name: 'accessToken',
      label: 'Access token',
      type: 'password',
      keyType: API_SECRET,
      isRequired: true,
      infoGuide: 'Login to Square developer portal, Navigate to Applications > Select / Create Application > Credentials to View your API Secret.',
    },
  ];
